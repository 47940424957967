html,
body {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-color: #efefef;
}


#root {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: stretch;
  height: 100%;
  min-height: 100%;
}

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}
